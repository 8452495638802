import React, { useEffect, useRef } from 'react'
import { fixedAssets } from 'src/containers/PreCache'
import Amplitude from 'src/utils/amplitudeHelper'
import styled from 'styled-components'
import config from '../config'
import { SIZES } from '../styles/theme'
import { useAutoMute } from '../utils/useAutoMute'
import PageWithBg from 'src/components/PageWithBg'
import VideoJS from 'src/components/VideoJS'
import videojs from 'video.js'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'
import { useDispatch } from 'react-redux'

const Page = styled.div`
  overflow: hidden;

  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .chat {
    max-width: 425px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 70%;
    bottom: 0;
    right: 5%;
    z-index: 30;
    margin: 0 auto;
    background: #242526;
  }
  #programme-btn {
    position: absolute;
    top: 10%;
    left: 0;
    margin-top: 30px;
    display: block;
    span {
      cursor: pointer;
      background: rgba(0, 0, 0, 1);
      opacity: 0.8;
      color: #F8C605;
      position: absolute;
      font-weight: 600;
      font-size: 18px;
      height: 60px;
      width: 180px;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 28px 28px 0px 0px;
      transform: rotate(90deg) translateY(60px);
      transition: all 200ms ease-in;
      &:hover {
        color: #000;
        background: rgba(248, 198, 5, 1);
        border: 2px solid #000;
      }
    }
  }
`

export default function AuditoriumPage({ currentUser, streamUrl }) {
  useAutoMute()
  const dispatch = useDispatch()
  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    errorDisplay: false,
    fluid: true,
    poster: fixedAssets.auditoriumPoster,
    sources: [{
      src: streamUrl,
      type: 'application/x-mpegURL'
    }]
  }

  useEffect(() => {
    document.title = `${config.eventName} | Auditorium`
    Amplitude.visit('Auditorium')
  }, [])

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting')
    })

    player.on('dispose', () => {
      videojs.log('player will dispose')
    })
  }

  return (
    <Page>
      <PageWithBg bgImg={fixedAssets.auditorium}>
        <div className="mx-auto chat">
          <iframe
            src="https://app.sli.do/event/npxqtji1"
            height="100%"
            width="100%"
            frameBorder="0"
            style={{ minHeight: "600px" }}
            title="Slido"
          ></iframe>
        </div>
        <div className="content">
          {streamUrl.endsWith(".m3u8") ?
            <>
              <VideoJS
                options={videoJsOptions}
                onReady={handlePlayerReady}
              />
            </>
            :
            <iframe
              width="100%"
              height="500px"
              title="Live Video"
              src={`${streamUrl}?autoplay=1`}
              allow="autoplay; fullscreen; encrypted-media"
              allowFullScreen
              allowtransparency="true"
              frameBorder="0"
            />
          }
        </div>
        <div id="programme-btn" onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Agenda))}>
          <span>Programme</span>
        </div>
      </PageWithBg>
    </Page>
  )
}
