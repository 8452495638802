import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import routes from '../../routes'
import RegistrationTemplate from './RegistrationTemplate'
import { FONTS, SIZES } from 'src/styles/theme'
import { fixedAssets } from 'src/containers/PreCache'

const PostRegSection = styled.section`
  width: 100%;
  text-align: center;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  padding: 0 1em;

  .checkIcon {
    width: 100px;
  }

  .btn {
    max-width: 400px;
    margin: auto;
    margin-top: 1em;
    background: black;
    padding: 10px 30px;
  }

  .subtitle,
  .subtitle2 {
    font-weight: 500;
    font-size: 17px;
  }

  ${SIZES.mobile} {
    border-left: none;
    .checkIcon {
      width: 70px;
    }
    .title {
      font-size: 1.5em;
      margin-bottom: 1em;
    }

    .subtitle,
    .subtitle2 {
      font-weight: 500;
      font-size: 1em;
      margin: 1em;
    }

    .btn {
      font-size: 14px;
    }
  }
`

export default function PostRegisterPage({ type }: { type: 'physical' | 'virtual' | 'insticlient' }) {
  const displayMessage = () => {
    switch (type) {
      case 'physical':
        return (
          <>
            <h2 className="title">Thank You For Signing Up For Invest Asean 2023!</h2>
            <p className="subtitle">Please check your email for confirmation and further details.</p>
          </>
        )
      case 'virtual':
        return (
          <>
            <h2 className="title">Registration Successful!</h2>
            <p className="subtitle">Thank You For Signing Up For Invest Asean 2023.</p>
            <p className="subtitle2">Please check your email for confirmation and further details.</p>
          </>
        )
      case 'insticlient':
        return (
          <>
            <h2 className="title">Registration Successful!</h2>
            <p className="subtitle">Thank You For Signing Up For Invest Asean 2023.</p>
            <p className="subtitle2">We’ve received your registration. Your Invest ASEAN 2023 Corporate Access itinerary will be sent to you shortly.</p>
          </>
        )
    }
  }

  return (
    <RegistrationTemplate>
      <PostRegSection>
        <img src={fixedAssets.checkIcon} className="checkIcon d-block mx-auto mb-4" alt="success" />
        {displayMessage()}
        <Link to={routes.login} className="btn btn-dark d-block py-2">
          LOG IN
        </Link>
      </PostRegSection>
    </RegistrationTemplate>
  )
}
