import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import { MOBILE_THRESHOLD, SIZES } from 'src/styles/theme'
import Amplitude from 'src/utils/amplitudeHelper'
import { useWindowSize } from 'src/utils/useWindowSize'
import styled from 'styled-components'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const SharedModal = styled(Modal)`
  left: ${(props) => (props.open ? '0' : '100%')};
  transition: 1s !important;
  scrollbar-width: thin;
  .modal-dialog {
    width: 100%;
    max-width: 75%;
    margin: 0 !important;
    margin-left: auto !important;
    border-radius: 0;
  }
  .modal-content {
    border-radius: 0;
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.55);
  }
  .react-pdf__Page__canvas {
    width: 100%;
    max-width: 100% !important;
  }
  .react-pdf__Page__textContent {
    display: none;
  }

  .modal-header {
    padding: 12px;
    border: none;
    display: block;
    background: rgba(0, 0, 0, 0.55);
    border-radius: 0;
    position: sticky;
    top: 0;
    z-index: 1000;

    button.close {
      opacity: 1;
      padding: 4px auto !important;
      z-index: 100;
      position: absolute;
      top: 5px;
      right: 20px;
      span {
        font-weight: 400;
        font-size: 34px;
        color: #f8c605;
        text-shadow: none;
      }
    }
  }
  p.file-description {
    padding: 8px auto;
    font-size: 16px;
    margin: 0;
    color: #f8c605;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
  }

  .modal-body {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
  .flex-container {
    display: flex;
    justify-content: center;
  }
  .buttons {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    z-index: 1000;
  }

  header {
    display: none;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0.5rem;
  }
  aside {
    position: fixed;
    top: 100px;
    right: 75%;
    transition: 1s;
    z-index: 1000;
    opacity: 0;
    animation: appear 500ms 1s forwards;
    display: ${(props) => (props.open ? 'block' : 'none')};
  }
  .side-tab {
    background: rgba(0, 0, 0, 0.55);
    width: 100px;
    color: #f8c605;
    border: 2px solid #f8c605;
    border-right: none;
    padding: 8px 16px;
    margin-bottom: 1rem;
    letter-spacing: 2px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    transition: all 300ms ease-in;
    border-radius: 10px 0px 0px 10px;
  }
  aside {
    .active {
      background: rgba(248, 198, 5, 0.85);
      color: #000;
      border: 2px solid rgba(0, 0, 0, 0.55);
      border-right: none;
    }
  }
  .session-tab {
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    letter-spacing: 2px;
    border-bottom: 2px solid white;
    transition: all 300ms ease-in;
  }
  header {
    .active {
      color: #f8c605;
      border-bottom: 2px solid #f8c605;
    }
  }
  .appear {
    opacity: 0;
    animation: appear 500ms forwards;
  }
  ${SIZES.mobile} {
    header {
      display: flex;
      position: sticky;
      top: 49.5px;
      z-index: 1000;
      background: rgba(0, 0, 0, 0.55);
    }
    aside {
      display: none;
    }
    .modal-dialog {
      max-width: 100%;
    }
    .modal-header {
      padding: 8px;
      display: flex;
      align-items: center;
      button.close {
        position: static;
        margin: 0;
        padding: 0;
      }
    }
    .modal-body {
      position: static;
      overflow: initial;
    }
    p.file-description {
      font-size: 14px;
      text-align: left;
    }
    .modal-content {
      background: rgba(0, 0, 0, 0.5);
    }
    .buttons {
      position: static;
    }
    .zoom-btn {
      margin: 0 2px;
      width: 30px;
      height: 30px;
    }
  }
`

export const SpeakersProfileModal = ({ showModal, hideModal }) => {
  const { windowWidth } = useWindowSize()

  const [numPages, setNumPages] = useState(null)
  const [scale, setScale] = useState(1.0)
  const [selectedTab, setSelectedTab] = useState(1)
  const [open, setOpen] = useState(false)

  const pdfWidth = windowWidth <= MOBILE_THRESHOLD ? windowWidth : 744

  useEffect(() => {
    Amplitude.clickSpeaker()
    setOpen(true)
  }, [])

  const handleZoomIn = () => {
    if (scale < 1.4) {
      setScale(scale + 0.1)
    }
  }

  const handleZoomOut = () => {
    if (scale > 0.5) {
      setScale(scale - 0.1)
    }
  }

  const handleDownload = () => {
    const links = [
      'https://cloudexpo-demo.s3.ap-southeast-1.amazonaws.com/speaker-demo-2.pdf',
      'https://cloudexpo-demo.s3.ap-southeast-1.amazonaws.com/speaker-demo-1.pdf',
    ]
    window.open(links[selectedTab - 1], '_blank')
  }

  const toggleModal = () => {
    setOpen(false)
    setTimeout(hideModal, 900)
  }

  return (
    <SharedModal open={open} show={showModal} onHide={toggleModal} size="lg" aria-labelledby="gallery-pdf-modal" centered animation={false}>
      <aside>
        <div className={`${selectedTab === 1 && 'active'} side-tab`} onClick={() => setSelectedTab(1)}>
          <span>DAY 1</span>
        </div>
        <div className={`${selectedTab === 2 && 'active'} side-tab`} onClick={() => setSelectedTab(2)}>
          <span>DAY 2</span>
        </div>
      </aside>
      <Modal.Header closeButton>
        <p className="file-description">Speakers' Profile</p>
        <div>
          <div className="buttons noselect">
            <div className="zoom-btn" onClick={handleDownload}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="#000" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline stroke="#000" points="7 10 12 15 17 10"></polyline>
                <line stroke="#000" x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
            </div>
            <div className="zoom-btn" onClick={handleZoomIn}>
              +
            </div>
            <div className="zoom-btn" onClick={handleZoomOut}>
              -
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <header>
          <div className={`${selectedTab === 1 && 'active'} session-tab`} onClick={() => setSelectedTab(1)}>
            <span>DAY 1</span>
          </div>
          <div className={`${selectedTab === 2 && 'active'} session-tab`} onClick={() => setSelectedTab(2)}>
            <span>DAY 2</span>
          </div>
        </header>
        <div className="flex-container">
          {selectedTab === 1 && (
            <div className="appear">
              <Document
                file="https://cloudexpo-demo.s3.ap-southeast-1.amazonaws.com/speaker-demo-2.pdf"
                loading={<p className="text-center text-white mt-3">Loading...</p>}
                onLoadSuccess={({ numPages }) => {
                  setNumPages(numPages)
                }}
              >
                {Array.apply(null, Array(numPages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page pageNumber={page} scale={scale} width={pdfWidth} />
                  ))}
              </Document>
            </div>
          )}
          {selectedTab === 2 && (
            <div className="appear">
              <Document
                file="https://cloudexpo-demo.s3.ap-southeast-1.amazonaws.com/speaker-demo-1.pdf"
                loading={<p className="text-center text-white mt-3">Loading...</p>}
              >
                <Page pageNumber={1} scale={scale} width={pdfWidth} />
              </Document>
            </div>
          )}
        </div>
      </Modal.Body>
    </SharedModal>
  )
}
