const routes = {
  root: '/',

  physicalPreReg: '/in-person',
  virtualPreReg: '/virtual',
  instiPreReg: '/corporate-access',

  physicalReg: '/in-person/form',
  virtualReg: '/virtual/form',
  instiReg: '/corporate-access/form',

  physicalPostReg: '/in-person/complete',
  virtualPostReg: '/virtual/complete',
  instiPostReg: '/corporate-access/complete',

  landing: '/landing',
  register: '/registration',
  postRegister: '/registration/complete',
  resetPassword: '/registration/create-password',
  forgotPassword: '/registration/forgot-password',
  login: '/login',
  sso: '/sso',

  notifications: '/notifications',
  menu: '/menu',

  lobby: '/lobby',

  networking: '/networking-lounge',

  auditorium: '/auditorium-hall',

  booth1: '/booths/1',
  booth2: '/booths/2',
  booth3: '/booths/3',
  booth4: '/booths/4',
  booth5: '/booths/5',

  speakers: '/speakers',
  programme: '/programme',
  virtualSpeaker: '/speakers-profile',
  agenda: '/agenda',
  test: '/test-live',

  lucky: '/lucky-draw',
  questions: '/live-questions',

  onDemand: '/gallery',
  onDemandOral: '/on-demand/oral-presentations',
  onDemandPoster: '/on-demand/e-poster-presentations',
  onDemandBbs: '/on-demand/best-basic-science-award',
} as const

type Route = typeof routes
export type RoutesType = Route[keyof Route]

export default routes
