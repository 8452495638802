import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import routes from '../routes'
import { NotificationDb } from '../firebaseConfig'
import { useListVals } from 'react-firebase-hooks/database'
import Amplitude from '../utils/amplitudeHelper'
import { logoutUser } from 'src/ducks/auth'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from './GlobalModals/GlobalModalContainer'

const MobileNav = React.memo(() => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector((state) => state.auth.currentUser)
  const [values, loading] = useListVals(NotificationDb)
  const [unread, setUnread] = useState(false)

  useEffect(() => {
    if (values && !loading){
      if (values.length < localStorage.getItem('IA_notifications')) {
        localStorage.setItem('IA_notifications', 0)
      }
      setUnread(values.length > localStorage.getItem('IA_notifications'))
    }
  }, [values, loading])

  const handleNotification = () => {
    Amplitude.clickNotification()
    history.push(routes.notifications)
    setUnread(false)
  }

  const handleLogout = () => {
    Amplitude.clickLogout()
    logoutUser(dispatch, currentUser, location.pathname, 'You have logged out')
  }

  return (
    <Page>
      <div className="navbar">
        <Link className="nav-item" to={routes.lobby}>
          <svg width="19" height="17.041" viewBox="0 0 19 17.041">
            <path d="M14.25,17.042H4.75a2.378,2.378,0,0,1-2.375-2.375V6.578l-1.118.813a.786.786,0,0,1-.465.152A.792.792,0,0,1,.326,6.11L8.1.455a2.376,2.376,0,0,1,2.794,0L18.673,6.11a.791.791,0,0,1,.175,1.106.8.8,0,0,1-.641.326.783.783,0,0,1-.465-.152l-1.118-.813v8.088A2.378,2.378,0,0,1,14.25,17.042ZM8.708,8.334h1.583a2.377,2.377,0,0,1,2.375,2.374v4.75H14.25a.793.793,0,0,0,.792-.792V5.427L9.966,1.734a.793.793,0,0,0-.932,0L3.958,5.427v9.239a.793.793,0,0,0,.792.792H6.333v-4.75A2.377,2.377,0,0,1,8.708,8.334Zm0,1.583a.792.792,0,0,0-.792.791v4.75h3.167v-4.75a.792.792,0,0,0-.792-.791Z" transform="translate(0 0)" fill="#e5e5e5"/>
          </svg>
        </Link>
        <div className={`nav-item ${unread ? 'with-shake' : ""}`} onClick={handleNotification}>
          <svg width="15.554" height="17.933" viewBox="0 0 15.554 17.933">
            <path d="M13.554,14.982H2a2.024,2.024,0,0,1-2-2.043v-.346a2.317,2.317,0,0,1,1.257-2.078A1.133,1.133,0,0,0,1.87,9.6l.4-4.459A5.684,5.684,0,0,1,4.046,1.478a5.447,5.447,0,0,1,7.462,0A5.684,5.684,0,0,1,13.287,5.14l.4,4.459a1.129,1.129,0,0,0,.609.913,2.32,2.32,0,0,1,1.261,2.08v.346A2.024,2.024,0,0,1,13.554,14.982ZM7.777,1.362A4.142,4.142,0,0,0,4.945,2.484a4.315,4.315,0,0,0-1.351,2.78L3.2,9.723a2.483,2.483,0,0,1-1.341,2.008.96.96,0,0,0-.523.862v.346A.675.675,0,0,0,2,13.62H13.554a.675.675,0,0,0,.667-.681v-.346a.96.96,0,0,0-.52-.861,2.487,2.487,0,0,1-1.345-2.008l-.4-4.459a4.316,4.316,0,0,0-1.35-2.78A4.141,4.141,0,0,0,7.777,1.362Z" fill="#e5e5e5"/>
            <path d="M1.333,3.4V2.5a1.111,1.111,0,1,1,2.222,0V3.4H4.888V2.5A2.471,2.471,0,0,0,2.444,0,2.471,2.471,0,0,0,0,2.5V3.4Z" transform="translate(10.221 17.933) rotate(180)" fill="#e5e5e5"/>
          </svg>
          <div className='notification-indicator' style={unread ? { backgroundColor: 'red' } : { display: 'none' }}></div>
        </div>
        <div className='nav-item' onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Agenda))}>
          <svg width="28" height="26" viewBox="0 0 28 26">
            <g id="Menu" transform="translate(0.383 -0.094)">
              <g transform="translate(0 0)">
                <g transform="translate(0 0)">
                  <g transform="translate(-0.383 0.094)" fill="none" stroke="#e5e5e5" strokeWidth="2.5">
                    <ellipse cx="14" cy="13" rx="14" ry="13" stroke="none" />
                    <ellipse cx="14" cy="13" rx="12.75" ry="11.75" fill="none" />
                  </g>
                  <g transform="translate(8.376 8.265)">
                    <path
                      d="M-1571.5,262.678h10.764"
                      transform="translate(1571.5 -262.678)"
                      fill="none"
                      stroke="#e5e5e5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                    />
                    <path
                      d="M-1571.5,262.678h10.764"
                      transform="translate(1571.5 -258.28)"
                      fill="none"
                      stroke="#e5e5e5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                    />
                    <path
                      d="M-1571.5,262.678h10.764"
                      transform="translate(1571.5 -253.882)"
                      fill="none"
                      stroke="#e5e5e5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2.5"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className='nav-item' onClick={handleLogout}>
          <svg width="16.584" height="17.589" viewBox="0 0 16.584 17.589">
            <path d="M16.081.67V9.425a1.279,1.279,0,0,1-1.256,1.3H2.764a1.279,1.279,0,0,1-1.256-1.3V.67A.716.716,0,0,0,.754,0,.716.716,0,0,0,0,.67V9.425a2.7,2.7,0,0,0,2.764,2.636H14.825a2.7,2.7,0,0,0,2.764-2.636V.67a.759.759,0,0,0-1.508,0Z" transform="translate(12.061) rotate(90)" fill="#e5e5e5"/>
            <path d="M5.246,9.328a.755.755,0,0,1,0-1.066L7.98,5.528H.452C.2,5.528,0,5.191,0,4.775S.2,4.02.452,4.02H7.979L5.246,1.287A.754.754,0,0,1,6.313.221l4.02,4.02a.754.754,0,0,1,0,1.067l-4.02,4.02a.754.754,0,0,1-1.067,0Z" transform="translate(6.03 3.97)" fill="#e5e5e5"/>
          </svg>
        </div>
      </div>
    </Page>
  )
})

const Page = styled.div`
  width: 100vw;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  background: #3A3D45;

  .navbar {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  #menuBtn {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    #halfCircle {
      position: absolute;
      width: 60px;
      height: 30px;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 1;
      border-radius: 0 0 10rem 10rem;
    }
    #menuIcon {
      z-index: 100;
      border-radius: 50%;
      background: #2E4B4C;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 20px;
      }
    }
  }
  #menuOverlay {
    padding: 1.5rem;
    background: #569099;
    color: #fff;
    text-align: center;
    border-radius: 28px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90vw;
    height: 125px;
    z-index: 99;
    transition: all 300ms ease-in;
  }
  
  .nav-item {
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .notification-indicator {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 4px;
    border-radius: 50%;
    position: absolute;
    top: 4.5%;
    right: 10%;
    transition: all 300ms ease;
  }
`

export default MobileNav
