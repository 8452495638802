import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useHistory } from 'react-router-dom'
import config from '../../config'
import { loginUser } from '../../ducks/auth'
import routes from '../../routes'
import { ForgetPassword } from './ForgetPassword'
import RegistrationTemplate from '../registration/RegistrationTemplate'
import { Button, Form } from 'react-bootstrap'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    loginUser(dispatch, history, {
      email,
      password,
    })
  }
  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  useEffect(() => {
    document.title = `${config.eventName} | Login`
  }, [])

  const showHelpDesk = () => {
    const cpt = window.chaport
    cpt.on('ready', function () {
      cpt.open()
    })
    cpt.on('collapsed', function () {
      cpt.close()
    })
  }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  } else {
    return (
      <RegistrationTemplate showCountdown>
        <div className="formWrapper loginForm">
          <div className='card'>
            <h5 className="text-warning mb-4">Enter Your Credentials To Log In</h5>

            <Form onSubmit={handleLogin}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <input className="form-input" type="email" placeholder="Email" value={email} onChange={onEmailInput} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Access Code</Form.Label>
                <input className="form-input" type="text" value={password} placeholder="Access Code" onChange={onPasswordInput} />
              </Form.Group>

              <ForgetPassword />

              <div className="btn-container d-flex justify-content-between mx-auto mt-4">
                <Button type="submit" className="btn btn-warning d-block w-100 text-center py-2" variant="default" disabled={!(email.length && password.length) || isFetching} onClick={handleLogin}>
                  <span className="d-block mr-3 font-weight-bold">{isFetching ? 'SUBMITTING...' : 'LOGIN'}</span>
                </Button>
              </div>
            </Form>
            <Link to={routes.virtualPreReg} className='mt-3' style={{ textDecoration: 'none', textAlign: 'center'}}>
              <small className='text-muted'>New here? Click here to sign up now.</small>
            </Link>
            <div className='text-center'>
              <small className='text-muted' style={{cursor: 'pointer'}} onClick={showHelpDesk}>Need help?</small>
            </div>
          </div>
        </div>
      </RegistrationTemplate>
    )
  }
}