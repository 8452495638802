import AOS from 'aos'
import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import config from './config'
import routes from './routes'

import { PreCache } from './containers/PreCache'

import ReactGA from 'react-ga4'
import Media from 'react-media'
import { SIZES } from './styles/theme'

import InstiRegistration from './pages/registration/InstiRegistration'
import PhysicalRegistration from './pages/registration/PhysicalRegistration'
import PostRegisterPage from './pages/registration/PostRegisterPage'
import PreRegisterPage from './pages/registration/PreRegisterPage'
import VirtualRegistration from './pages/registration/VirtualRegistration'
import { LoginPage } from './pages/LoginPage'
import WelcomePage from './pages/WelcomePage'
import { LobbyPage } from './webOnlyPages/LobbyPage'
import PrivateRoute from './containers/PrivateRoute'
import AuditoriumContainer from './containers/AuditoriumContainer'
import MobileLobby from './mobileOnlyPages/MobileLobby'
import MobileNotifications from './mobileOnlyPages/MobileNotifications'
import BoothPage from './webOnlyPages/BoothPage'
import MobileBooth from './webOnlyPages/MobileBooth'
import PhysicalProgrammePage from './pages/PhysicalProgrammePage'
import SpeakerProfilePage from './pages/SpeakerProfilePage'
import TestLivePage from './pages/TestLivePage'
import ActiveUserCountPage from './pages/ActiveUserCountPage'
import { DesktopOnDemand } from './webOnlyPages/DesktopOnDemand'
import { MobileOnDemand } from './mobileOnlyPages/MobileOnDemand'

declare global {
  interface Window {
    chaport: any
  }
}
ReactGA.initialize('G-YTL11PFZRT')

function App() {
  const location = useLocation()

  useEffect(() => {
    document.title = config.eventName
    window.chaport.q('hideLauncher')

    AOS.init({
      easing: 'ease-in-out',
      once: true,
      offset: 50,
      anchorPlacement: 'top-bottom',
    })
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      })
    }
  }, [location])

  return useMemo(() => {
    return (
      <div id="innerBody">
        <PreCache deviceType={'desktop'}>
          <Switch>
            <Route exact path={routes.physicalPreReg} component={() => <PreRegisterPage type="physical" />} />
            <Route exact path={routes.virtualPreReg} component={() => <PreRegisterPage type="virtual" />} />
            <Route exact path={routes.instiPreReg} component={() => <PreRegisterPage type="insticlient" />} />

            <Route exact path={routes.physicalReg} component={PhysicalRegistration} />
            <Route exact path={routes.virtualReg} component={VirtualRegistration} />
            <Route exact path={routes.instiReg} component={InstiRegistration} />

            <Route exact path={routes.physicalPostReg} component={() => <PostRegisterPage type="physical" />} />
            <Route exact path={routes.virtualPostReg} component={() => <PostRegisterPage type="virtual" />} />
            <Route exact path={routes.instiPostReg} component={() => <PostRegisterPage type="insticlient" />} />

            <Route exact path={routes.root} component={WelcomePage} />
            <Route exact path={routes.login} component={LoginPage} />

            <Route exact path={routes.programme} component={PhysicalProgrammePage} />
            <Route exact path={routes.speakers} component={SpeakerProfilePage} />
            <Route exact path={routes.test} component={TestLivePage} />
            
            {/* <Route exact path="/session-reports" component={SessionReportPage} /> */}
            <PrivateRoute blockedRoles={['participant', 'exhibitor', 'speaker']} exact path="/realtime" component={ActiveUserCountPage} />
            {/* <PrivateRoute blockedRoles={['participant', 'exhibitor', 'speaker']} exact path="/sessions" component={UserSessionPage} /> */}
          </Switch>
        </PreCache>

        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) =>
            matches ? (
              // MOBILE ROUTES
              <PreCache deviceType={'mobile'}>
                <Switch>
                  <PrivateRoute mobileNav path={routes.lobby} component={MobileLobby} />
                  <PrivateRoute mobileNav path={routes.auditorium} component={AuditoriumContainer} />
                  <PrivateRoute mobileNav path={routes.notifications} component={MobileNotifications} />
                  <PrivateRoute mobileNav path="/booths/:boothId" component={MobileBooth} />
                  <PrivateRoute mobileNav path={routes.onDemand} component={MobileOnDemand} />
                </Switch>
              </PreCache>
            ) : (
              // DESKTOP ROUTES
              <PreCache deviceType={'desktop'}>
                <Switch>
                  <PrivateRoute fixedNav path={routes.lobby} component={LobbyPage} />
                  <PrivateRoute fixedNav path={routes.auditorium} component={AuditoriumContainer} />
                  <PrivateRoute fixedNav path="/booths/:boothId" component={BoothPage} />
                  <PrivateRoute fixedNav path={routes.onDemand} component={DesktopOnDemand} />
                </Switch>
              </PreCache>
            )
          }
        </Media>
      </div>
    )
  }, [])
}

export default App
