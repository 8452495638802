import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import RegistrationTemplate from './registration/RegistrationTemplate'

const PreRegSection = styled.section`
  width: 100%;
  text-align: center;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${SIZES.mobile} {
    border-left: none;
  }

  h3 {
    font-weight: 600;
  }
  .btn {
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    margin-top: 0.8em;
  }

  .disclaimer {
    max-width: 400px;
  }
`

export default function WelcomePage() {
  return (
    <RegistrationTemplate showCountdown>
      <PreRegSection>
      <h3>Log In To Participate Virtually</h3>
        <Link to={routes.login} className="btn btn-dark d-block py-2">
          LOG IN
        </Link>
        <Link to={routes.virtualReg} className="btn btn-dark d-block py-2">
          SIGN UP
        </Link>
      </PreRegSection>
    </RegistrationTemplate>
  )
}
