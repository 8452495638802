import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from '../config'
import { SIZES } from '../styles/theme'

const Wrapper = styled.div`
  width: 100%;
  .time-wrapper {
    width: 90%;
    margin: 0 auto;
    margin-right: 0;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */

    ${SIZES.mobile} {
      margin-right: auto;
    }
    .time-card {
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      min-width: 90px;
      width: 8vw;
      max-width: 120px;
      margin-bottom: 5px;
      padding-top: 15px;
      padding-bottom: 15px;

      p {
        font-weight: 400;
        position: relative;
        color: #525252;
        font-size: 14px;
        margin: 0;
        text-transform: capitalize;
      }
      
      h2 {
        font-weight: 600;
        font-size: 2.5rem;
        margin: 0;
        position: relative;
      }
      .bg {
        position: absolute;
        border-top: 100px solid #f0f0f0;
        border-left: 80px solid #fff;
      }

      ${SIZES.mobile} {
        margin: 0 10px;
        margin-bottom: 10px;
        min-width: 60px;
        width: 10vw;
        p {
          font-size: 0.75rem;
        }
      
        h2 {
          font-size: 2rem;
        }
        .bg {
          border-top: 85px solid #f0f0f0;
          border-left: 70px solid #fff;
        }
      }

    }
  }
`

export default function CountdownTimer() {

  const calculateTimeLeft = () => {
    const { year, month, day, hour, minute } = config.EVENT_START_TIME
    const difference = +new Date(year, month - 1, day, hour, minute, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card" key={interval}>
        <div className='bg'></div>
        <h2>{timeLeft[interval]}</h2>
        <p>{interval}</p>
      </div>
    )
  })

  const eventStarted = !timerComponents.length

  if (eventStarted) {
    return <></>
  }

  return (
    <Wrapper>
      <div className="time-wrapper">
        {timerComponents.length && timerComponents}
      </div>
    </Wrapper>
  )
}
