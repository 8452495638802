import videoImage from '../../images/gallerium/onDemand/video-thumbnail.png'

import { onDemandVideosType } from './videos'

export interface onDemandCategoryType {
  id: number
  title: string
  categoryName?: string
  thumbnail: string
  profile?: string
  destination?: string
  embedUrl?: string
  videos?: Array<onDemandVideosType>
}

const onDemandCategories: Array<onDemandCategoryType> = [
  {
    id: 1,
    thumbnail: videoImage,
    title: "Opening Address by Yang Amat Berhormat Dato' Seri",
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 2,
    thumbnail: videoImage,
    title: 'Conference Context Setting',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 3,
    thumbnail: videoImage,
    title: "ASEAN's Rising Star - The Next Wave",
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 6,
    thumbnail: videoImage,
    title: 'Capital Allocations in the Post-Pandemic World',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 8,
    thumbnail: videoImage,
    title: 'Investing through the Fog: Picking Winners and Managing Risk in Unprecendented Times',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 9,
    thumbnail: videoImage,
    title: 'Investment Reboot: Where are the Opportunities',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 10,
    thumbnail: videoImage,
    title: 'Press Conference - Part 1',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 11,
    thumbnail: videoImage,
    title: 'Press Conference - Part 2',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 12,
    thumbnail: videoImage,
    title: 'Is ESG Still Relevant For Global Investors? Who Will Win The Battle For Green Industrialisation?',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 13,
    thumbnail: videoImage,
    title: 'The Next Great Leap: Unearthing ASEAN Opportunities from AI & Digitalisation',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
  {
    id: 15,
    thumbnail: videoImage,
    title: 'Winning With Alternatives: Insights From Top Managers',
    embedUrl: 'https://player.vimeo.com/video/70591644?h=b8c796fff8&title=0&byline=0&portrait=0&autoplay=1&muted=0',
  },
]

export { onDemandCategories }
