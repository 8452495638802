import routes from './routes'
import { LobbyEntryProps, LobbyEntryTypes } from './components/LobbyEntry/LobbyEntry'

// audio
import LobbyMusic from './images/audio/lobby.mp3'

// icons & decorations
import { fixedAssets } from './containers/PreCache'
import { GlobalModalTypes } from './components/GlobalModals/GlobalModalContainer'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://virtual-demo-server.cloudexpo.my',
}

const API_KEYS = {
  amplitude: '1e40490c6a83a25a85384be77425cd65',
}

const VOLUME_VALUE = 0.4

// Root page will show either EntrancePage or CountdownPage
// based on the following time setting
const EVENT_START_TIME = {
  year: 2023,
  month: 6,
  day: 20,
  hour: 8,
  minute: 0,
}

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 10%;
    left: 5%;
    right: 32%;
    bottom: 20%;
  `,
}

const lobbyEntries: Array<LobbyEntryProps & { id: string }> = [
  {
    id: 'auditorium-entrance',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.auditorium,
    pinImage: fixedAssets.auditoriumEntrance,
    css: `
      top: 47%;
      left: 9.4%;
      right: 64.5%;
      bottom: 6.8%;
    `,
    pinText: "AUDITORIUM",
    textCss: `
      top: 32%;
      left: 27%;
      right: 18%;
      bottom: 57%;
      transform: rotateX(0deg) rotateY(15deg);
    `,
  },
  {
    id: 'gallery-entrance',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.onDemand,
    pinImage: fixedAssets.ondemandEntrance,
    css: `
      top: 55.2%;
      left: 48.2%;
      right: 38.8%;
      bottom: 15.5%;
    `,
    pinText: "VIDEO ON-DEMAND",
    textCss: `
      top: 30%;
      left: 12.5%;
      right: 12.5%;
      bottom: 57%;
      transform: rotateX(0deg) rotateY(15deg);
    `,
  },
  {
    id: 'exhibition-entrance',
    type: LobbyEntryTypes.GlobalModal,
    modalId: GlobalModalTypes.EntrySelection,
    pinImage: fixedAssets.exhibitionEntrance,
    css: `
      top: 45%;
      left: 63%;
      right: 22%;
      bottom: 16%;
    `,
    pinText: "EXHIBITION HALL",
    textCss: `
      top: 30%;
      left: 7%;
      right: 22%;
      bottom: 42%;
      transform: rotateX(0deg) rotateY(-15deg);
    `,
  },
  {
    id: 'helpdesk',
    type: LobbyEntryTypes.Helpdesk,
    pinImage: fixedAssets.infoIcon,
    css: `
      top: 59%;
      left: 83.5%;
      right: 25%;
      bottom: 55%;
    `,
  },
  {
    id: 'speaker profile',
    type: LobbyEntryTypes.GlobalModal,
    modalId: GlobalModalTypes.Speakers,
    pinImage: fixedAssets.profileIcon,
    css: `
      top: 58.5%;
      left: 91.5%;
      right: 8.5%;
      bottom: 55.5%;
    `,
  },
  {
    id: 'welcome video',
    type: LobbyEntryTypes.GlobalModal,
    modalId: GlobalModalTypes.Welcome,
    pinImage: fixedAssets.playIcon,
    css: `
      top: 30%;
      left: 45%;
      right: 45%;
      bottom: 55%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  VOLUME_VALUE,
  EVENT_START_TIME,

  eventName: 'Maybank Invest ASEAN 2023',

  ...config,
}
