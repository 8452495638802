import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
// import closeTheatre from '../../images/mobile/close-theatre.png'
// import openTheatre from '../../images/mobile/open-theatre.png'
import Amplitude from '../../utils/amplitudeHelper'
import { useAutoMute } from '../../utils/useAutoMute'
import { fixedAssets } from 'src/containers/PreCache'
import config from 'src/config'
import VideoJS from 'src/components/VideoJS'
import videojs from 'video.js'
import MobileHeader from 'src/components/MobileHeader'
import { useDispatch } from 'react-redux'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'

const MobileAuditorium = ({ streamUrl }) => {
  useAutoMute()
  const dispatch = useDispatch()
  const playerRef = useRef(null)
  const [theatre, setTheatre] = useState(false)

  const videoContainerTheatreStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    zIndex: '99',
  }

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    errorDisplay: false,
    fluid: true,
    poster: fixedAssets.auditoriumPoster,
    sources: [
      {
        src: streamUrl,
        type: 'application/x-mpegURL',
      },
    ],
  }

  useEffect(() => {
    document.title = `${config.eventName} | Auditorium`
    Amplitude.visit(`Auditorium`)
  }, [])

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting')
    })

    player.on('dispose', () => {
      videojs.log('player will dispose')
    })
  }

  return (
    <Page>
      <MobileHeader />
      <div className="outer-wrapper">
        <div className="container">
          <div className="screen-container">
            <div className="video-container" style={theatre ? videoContainerTheatreStyle : {}}>
              {streamUrl.endsWith('.m3u8') ? (
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
              ) : (
                <iframe
                  width="100%"
                  height="500px"
                  title="Live Video"
                  src={`${streamUrl}?autoplay=1`}
                  allow="autoplay; fullscreen; encrypted-media"
                  allowFullScreen
                  allowtransparency="true"
                  frameBorder="0"
                />
              )}
            </div>
          </div>

          {/* <button type="button" className="theatre-button" onClick={() => setTheatre(true)}>
            <img src={openTheatre} className="theatre-button-icon" alt="theatre-icon" />
            <p className="theatre-button-label">Theatre mode</p>
          </button> */}
        </div>

        <div className="button-container">
          <div onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Agenda))} className="animated-btn">
            <span>Programme</span>
          </div>
        </div>

        <div className="chat-container">
          <iframe src="https://app.sli.do/event/npxqtji1" height="100%" width="100%" frameBorder="0" style={{ minHeight: '560px' }} title="slido"></iframe>
        </div>

        {/* {theatre && (
          <div className="theatre-close">
            <img src={closeTheatre} onClick={() => setTheatre(false)} className="theatre-close-icon" alt="close-icon" />
          </div>
        )} */}
      </div>
    </Page>
  )
}

const Page = styled.div`
  background-color: #f8c605;
  background-repeat: no-repeat;
  background-position: bottom center;
  @media (max-width: 1200px), (min-height: 1000px) {
    background-size: cover;
  }
  @media (min-width: 1201px) and (max-width: 2400px), screen and (min-height: 1000px) {
    background-size: 200%;
  }
  background-image: url(${fixedAssets.auditorium});
  background-size: cover;

  height: calc(100% - 50px);
  width: 100vw;
  overflow-y: scroll;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 98%;
    margin: auto;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-top: 58.5%;
    position: relative;
  }

  .video-container {
    width: 98%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);

    iframe,
    video {
      height: 100%;
      width: 100%;
    }
  }

  .button-container {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    div {
      text-transform: uppercase;
      background: #000000;
      padding: 4px 1rem;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      border: 2px solid #fff;

      &:hover {
        color: #fff;
        transform: scale(1.1);
      }
    }
  }

  .chat-container {
    width: 100%;
    height: 600px;
    margin-top: 12px;
  }

  .theatre-button {
    width: 140px;
    height: 30px;
    background: black;
    margin: 10px 10px 0px 0px;
    float: right;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0.6;
    padding: 0;
  }

  .theatre-button-label {
    color: white;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
  }

  .theatre-button-icon {
    width: 20px;
    margin: 0px 10px;
  }

  .theatre-close {
    color: white;
    background: black;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
  }

  .theatre-close-icon {
    width: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileAuditorium
