import Axios from 'axios'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SelectSearch from 'react-select-search'
import { toast } from 'react-toastify'
import countryList from 'src/constants/countryList'
import config from '../../config'
import routes from '../../routes'
import RegistrationTemplate from './RegistrationTemplate'
import { fullAttendeeTypes } from './corporateList'

export default function VirtualRegistration() {
  const history = useHistory()

  const [data, setData] = useState({
    attendeeType: null,
    attendeeTypeOthers: '',
    fullName: '',
    designation: '',
    company: '',
    officeNo: '',
    mobileNo: '',
    email: '',
    secEmail: '',
    country: '',
  })

  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(false)

  const handleInput = (e) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: name === 'email' ? value.toLowerCase() : value,
    })
  }

  const handleCountryInput = (val) => {
    setData({
      ...data,
      country: val,
    })
  }

  const handleCheckBox = (e) => {
    setAgree(!agree)
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    if (!agree) {
      toast('Please indicate that you have agree to terms presented in disclaimer before submit.')
      return
    }

    if (hasEmptyField) {
      toast('Please fill in required fields.')
      return
    }

    setLoading(true)
    const options = {
      attendee_type: data.attendeeType,
      attendee_type_others: data.attendeeTypeOthers,
      full_name: data.fullName,
      designation: data.designation,
      company: data.company,
      office_phone: data.officeNo,
      mobile_phone: data.mobileNo,
      email: data.email,
      secondary_email: data.secEmail,
      country: data.country,
      registered_for: 'Virtual',
    }
    Axios.post(`${config.apiUrl}/api/users`, options)
      .then(() => {
        history.push(routes.virtualPostReg)
      })
      .catch((err) => {
        toast(err?.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { attendeeType, attendeeTypeOthers, fullName, designation, company, officeNo, mobileNo, email, secEmail, country } = data

  const hasEmptyField = !(
    fullName.length &&
    designation.length &&
    company.length &&
    // officeNo.length &&
    mobileNo.length &&
    email.length &&
    country.length &&
    (attendeeType === 'Others (please specify)') == attendeeTypeOthers.length > 0 &&
    agree
  )

  return (
    <RegistrationTemplate>
      <div className="formWrapper">
        <div className="card">
          <h5 className="text-warning mb-4">Registration (Virtual)</h5>

          <Form>
            <Form.Group>
              <Form.Label>Select type of attendee</Form.Label>
              <select className="form-input" value={attendeeType} name="attendeeType" onChange={handleInput} required>
                <option value="" disabled selected>
                  - Select category -
                </option>
                {fullAttendeeTypes.map((item) => (
                  <option key={`specialty-${item}`} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Group>
            {attendeeType === 'Others (please specify)' && (
              <Form.Group>
                <Form.Label>Please specify</Form.Label>
                <input className="form-input" required type="text" name="attendeeTypeOthers" value={attendeeTypeOthers} onChange={handleInput} />
              </Form.Group>
            )}

            <Form.Group>
              <Form.Label>
                <span>Name</span>
                <span className="text-muted">(Eg. Tan Sri Data' Ahmad Hishak)</span>
              </Form.Label>
              <input className="form-input" required type="text" name="fullName" value={fullName} onChange={handleInput} placeholder="Full name with title" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Country</Form.Label>
              <SelectSearch search options={countryList} value={country} onChange={handleCountryInput} name="country" placeholder="- Select a country -" />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <span>Designation</span>
                <span className="text-muted">(Eg. Executive Financial Analyst)</span>
              </Form.Label>
              <input className="form-input" required type="text" name="designation" value={designation} onChange={handleInput} placeholder="Job Title" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Company Name</Form.Label>
              <input className="form-input" required type="text" name="company" value={company} onChange={handleInput} placeholder="Company Name" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Office No.</Form.Label>
              <input className="form-input" required type="text" name="officeNo" value={officeNo} onChange={handleInput} placeholder="Office No" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mobile No.</Form.Label>
              <input className="form-input" required type="text" name="mobileNo" value={mobileNo} onChange={handleInput} placeholder="Mobile No" />
            </Form.Group>

            <Form.Group>
              <Form.Label>Email</Form.Label>
              <input className="form-input" required type="email" name="email" value={email} onChange={handleInput} placeholder="Email" />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <span>Secondary Email</span>
                <span className="text-muted">(Optional)</span>
              </Form.Label>
              <input className="form-input" required type="email" name="secEmail" value={secEmail} onChange={handleInput} placeholder="Email" />
            </Form.Group>

            <div className="privacy mb-2">
              <div className="form-check">
                <input type="checkbox" className="check-box" checked={agree} onChange={handleCheckBox} />
                <label className="form-check-label text-left d-block" onClick={handleCheckBox}>
                  By sharing your email, you agree to our&nbsp;
                  <a href="https://cloudexpo.my/terms-of-use" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                  &nbsp;and&nbsp;
                  <a href="https://cloudexpo.my/terms-of-use" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </label>
              </div>
            </div>

            <div className="btn-container d-flex justify-content-between mx-auto mt-4">
              <Button type="submit" className="btn btn-warning d-block w-100 text-center py-2" variant="default" disabled={loading} onClick={handleSubmit}>
                <span className="d-block mr-3 font-weight-bold">{loading ? 'SUBMITTING...' : 'SUBMIT REGISTRATION'}</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </RegistrationTemplate>
  )
}
