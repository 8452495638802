import React, { Suspense, useEffect, useState } from 'react'
import Axios from 'axios'
import Media from 'react-media'
import { Loader } from 'src/components/shared/Loader'
import { SIZES } from 'src/styles/theme'
import MobileAuditorium from 'src/mobileOnlyPages/MobileAuditorium'
import AuditoriumPage from 'src/webOnlyPages/AuditoriumPage'
import config from 'src/config'
import { useDispatch, useSelector } from 'react-redux'
import { getCountryCode } from 'src/ducks/auth'

export default function AuditoriumContainer() {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [streamUrl, setStreamUrl] = useState('https://live-par-2-cdn-alt.livepush.io/live/bigbuckbunnyclip/index.m3u8')
  const { countryCode } = useSelector((state) => state.auth)

  useEffect(() => {
    if (countryCode === '') {
      dispatch(getCountryCode())
    }
  }, [countryCode, dispatch])

  useEffect(() => {
    if (countryCode.length > 0) {
      Axios.get(`${config.apiUrl}/api/live_stream?ip=${countryCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
        .then(({ status, data }) => {
          setStreamUrl(data.stream)
          setLoading(false)
        })
        .catch((err) => {
          console.error('Error: ', err)
        })
    }
  }, [countryCode])

  return (
    <Loader loading={loading}>
      <Suspense fallback={<div>Loading...</div>}>
        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) => (matches ? <MobileAuditorium streamUrl={streamUrl} /> : <AuditoriumPage streamUrl={streamUrl} />)}
        </Media>
      </Suspense>
    </Loader>
  )
}
